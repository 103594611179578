@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 95%;
  /* overflow: hidden; */
}

.listen_modal {
  background: transparent;
  box-shadow: none;
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
  }
}

.p-message{
  position: absolute;
  right: 15px;
}
